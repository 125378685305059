// Advantage One Vision colors

// primary

$light-primary: #64696e;
$light-primary-contrast: #ffffff;

$dark-primary: #64696e;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #ef7e3c;
$light-accent-contrast: #ffffff;

$dark-accent: #ef7e3c;
$dark-accent-contrast: #ffffff;

// common

$page-background: #64696e2f;
